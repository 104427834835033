<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-list shaped v-if="isShow">
        <v-list-item-group
                :value="selectedList"
                multiple>
            <template v-for="(item, i) in list">
                <v-list-item
                        class="type"
                        :key="`item-${i}`"
                        :value="item"
                        @click="selectedItem(item)">
                    <template v-slot:default="{ active, toggle }">
                        <v-list-item-action class="mr-1">
                            <v-checkbox
                                    :input-value="active"
                                    :true-value="item"
                                    color="#B78E55"
                                    @click="toggle"
                            ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="type text-left"
                                               v-text="item.title||item.city"></v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </template>
        </v-list-item-group>
    </v-list>
</template>
<script>
    export default {
        name: 'checked-group',
        props: {
            isShow: {
                type: Boolean,
                default: false
            },
            list: {
                type: Array,
                default: []
            },
            sList: {
                type: Array,
                default: []
            }
        },
        data(){
            return {
                selectedList: []
            }
        },
        watch: {
            sList(val){
                this.selectedList = val
                console.log('--11-----', this.list, val)
            },
            isShow(val){
                if (val) {
//                    this.selectedList = this.sList
                    console.log(this.selectedList)
                }
            }
        },
        methods: {
            selectedItem(e){
                this.$emit('selectCallBack', e)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .type {
        font-size: 18px !important;
        @media (max-width: $screen-md) {
            font-size: 14px !important;
        }
    }

    .v-item--active {
        .type {
            color: $primary-color !important;
        }
    }
</style>