<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="page">
        <app-header :navIndex="3"></app-header>
        <banner :data="banner"></banner>
        <div class="top-box">
            <div class="top-border hidden-md-and-up white"></div>
        </div>
        <div class="mt-md-n12 search-box d-flex flex-md-row align-center" v-if="false">
            <!--<v-icon class="pl-md-12">mdi-feature-search</v-icon>-->
            <span class="pl-5 pl-md-12 iconfont iconsousuo"></span>
            <input class="flex ml-3 ml-md-7" placeholder="搜索职位"/>
            <div class="search text-center white--text">搜索</div>
        </div>
        <section class="section-container mt-md-12 pt-md-2 pb-md-12">
            <div class="d-flex flex-column flex-md-row justify-md-space-between">
                <div class="item-hidden-sm left-box col-md-2 d-flex flex-md-column pa-0 mr-md-5">
                    <div class="type-top-box white text-center pt-md-4">
                        {{$i18n.t('common.join.position')}}
                        <checked-group :isShow="true" :list="typeList" :sList="selectTypeList"
                                       @selectCallBack="selectType"></checked-group>
                        <div class="pb-md-5 text-left pl-md-5 more" v-if="false">
                            <!--<span class="iconfont iconsousuo"></span>-->
                            更多
                        </div>
                    </div>
                    <div class="type-bottom-box white text-center mt-md-5 pt-md-4">
                        {{$i18n.t('common.join.city')}}
                        <checked-group :isShow="true" :list="cityList" :sList="selectCityList"
                                       @selectCallBack="selectCity"></checked-group>
                        <div class="pb-md-5 text-left pl-md-5 more" v-if="false">
                            <!--<v-icon>mdi-feature-search</v-icon>-->
                            <!--<span class="iconfont iconsousuo"></span>-->
                            更多
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 type-box pa-0 mt-5 mb-2 d-flex flex-row"
                     v-if="$vuetify.breakpoint.mdAndUp?false:true">
                    <div class="item item-lt white">
                        <div class="inner-info d-flex flex-row align-center justify-center pt-3 pb-3"
                             @click="showType=!showType;showCity=false">
                            <div class="mr-2">{{$i18n.t('common.join.position')}}</div>
                            <div class="img">
                                <v-img contain
                                       :src="showType?'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/arrow-up.png':'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/arrow-down.png'"></v-img>
                            </div>
                        </div>
                        <div class="white type-list type-lt"
                             v-if="showType">
                            <checked-group-app :isShow="showType" :list="typeList" :sList="selectTypeList"
                                               @selectCallBack="selectType"></checked-group-app>
                            <!--<checked-group :isShow="showType" :list="typeList" :sList="selectTypeList"
                                           @selectCallBack="selectType"></checked-group>-->
                        </div>
                    </div>
                    <div class="item item-rt white">
                        <div class="inner-info d-flex flex-row align-center justify-center  pt-3 pb-3"
                             @click="showCity=!showCity;showType=false">
                            <div class="mr-2">{{$i18n.t('common.join.city')}}</div>
                            <div class="img">
                                <v-img contain
                                       :src="showCity?'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/arrow-up.png':'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/arrow-down.png'"></v-img>
                            </div>
                        </div>
                        <div class="type-list type-rt white"
                             v-if="showCity">
                            <checked-group-app :isShow="showCity" :list="cityList" :sList="selectCityList"
                                               @selectCallBack="selectCity"></checked-group-app>
                            <!--<checked-group :isShow="showCity" :list="cityList" :sList="selectCityList"
                                           @selectCallBack="selectCity"></checked-group>-->
                        </div>

                    </div>
                </div>

                <div class="flex white item-box pa-md-6">
                    <div class="hidden-sm-and-down">{{$i18n.t('common.join.job')}}</div>
                    <div class="d-flex flex-column">
                        <v-hover class="pa-5 mb-5 mb-md-0 mt-md-5" v-slot:default="{hover}" v-for="(item,index) in list"
                                 :key="index">
                            <v-card :elevation="hover ? 6 : 0"
                                    :class="{ 'on-hover': hover }">
                                <div class="item-title font-weight-bold">{{item.position}}</div>
                                <div class="mt-4 mb-4 item-title font-weight-medium">{{item.type}}</div>
                                <div class="mb-5 desc" v-html="item.desc"></div>
                                <base-btn class="more-btn">{{$i18n.t('common.join.send')}}
                                    <span class="iconfont iconjiantou"></span>
                                    <!--<v-icon small>mdi-chevron-right</v-icon>-->
                                </base-btn>
                            </v-card>
                        </v-hover>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Banner from "../../../components/base/banner";
    import CheckedGroup from "../../../components/base/checked-group";
    import AppHeader from "../../../components/core/header/index";
    import CheckedGroupApp from "../../../components/base/checked-group-app";
    export default {
        components: {
            CheckedGroupApp,
            AppHeader,
            CheckedGroup,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: 'employee/join/social/bg.jpg',
                    title: this.$i18n.t('others.employee.join.title'),
                    enTitle: ''
                },
                typeList: [{id: 1, title: '销售经理'}, {id: 2, title: '渠道经理'}, {id: 3, title: '大客户经理'}, {
                    id: 4,
                    title: '薪资福利经理'
                }],
                cityList: [{id: 1, city: '上海'}, {id: 2, city: '北京'}, {id: 3, city: '广州'}, {id: 4, city: '深圳'}],
                list: [{
                    position: 'Sales Manager', type: '上海 | 社招',
                    desc: '1.Follow up Manager arrangement of daily sales operation including sales plan/team target acchievement/promotion plan to develop more sales value.' +
                    '<br>2.Using sales skills anf product konwledge to sell and directly responsible for on trade accounts in order to achieve monthly sales target of Cross Channel'
                }, {
                    position: 'Sales Manager', type: '上海 | 社招',
                    desc: '1.Follow up Manager arrangement of daily sales operation including sales plan/team target acchievement/promotion plan to develop more sales value.' +
                    '<br>2.Using sales skills anf product konwledge to sell and directly responsible for on trade accounts in order to achieve monthly sales target of Cross Channel'
                }, {
                    position: 'Sales Manager', type: '上海 | 社招',
                    desc: '1.Follow up Manager arrangement of daily sales operation including sales plan/team target acchievement/promotion plan to develop more sales value.' +
                    '<br>2.Using sales skills anf product konwledge to sell and directly responsible for on trade accounts in order to achieve monthly sales target of Cross Channel'
                }],
                selectTypeList: [],
                selectCityList: [],
                showCity: false,
                showType: false,
            }
        },
        created(){
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "加入我们"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData(){
                this.$api.web.getCity({}).then(res => {
                    if (res.rows.length > 0) {
                        this.cityList = res.rows
                    }
                })
                this.$api.web.getPosition({}).then(res => {
                    if (res.rows.length > 0) {
                        this.typeList = res.rows
                    }
                })
                this.getList()
            },
            getList(){
                var cityList = []
                var positionList = []

                this.selectTypeList.forEach(item => {
                    positionList.push(item.title)
                })
                this.selectCityList.forEach(item => {
                    cityList.push(item.id)
                })

                this.$api.web.getRecruitment({
                    type: 2,
                    cityId: cityList,
                    position: positionList
                }).then(res => {
                    if (res.rows.length > 0) {
                        this.list = res.rows
                    }
                })
            },
            selectType(row){
                var index = this.selectTypeList.findIndex(item => {
                    if (item.id == row.id) {
                        return true;
                    }
                })
                if (index > -1) {
                    this.selectTypeList.splice(index, 1)
                } else {
                    this.selectTypeList.push(row)
                }
//                console.log(this.selectTypeList)
                this.showType = false
                this.showCity = false
                this.getList()
            },
            selectCity(row){
                var index = this.selectCityList.findIndex(item => {
                    if (item.id == row.id) {
                        return true;
                    }
                })
                if (index > -1) {
                    this.selectCityList.splice(index, 1)
                } else {
                    this.selectCityList.push(row)
                }
//                console.log(index, row, this.selectCityList, this.selectCityList.length)
                this.showType = false
                this.showCity = false
                this.getList()
            }
        }
    }
</script>
<style lang="scss" scoped>
    .page {
        background-color: #FAFAFA;
    }

    .search-box {
        position: relative;
        width: 1000px;
        height: 90px;
        margin: auto;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0px 2px 5px 0px rgba(151, 151, 151, 0.4);
        span {
            font-size: 30px !important;
            color: #7E7E7E;
        }
        input {
            outline-style: none;
            color: $primary-color;
            font-size: 20px;
        }
        input::-webkit-input-placeholder {
            color: #D8D8D8;
        }
        .search {
            width: 185px;
            height: 100%;
            line-height: 90px;
            font-size: 20px;
            background-color: $primary-color;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            cursor: pointer;
        }
    }

    .type-top-box {
        font-size: 18px;
        border-top-left-radius: 20px;
    }

    .type-bottom-box {
        font-size: 18px;
        border-bottom-left-radius: 20px;
    }

    .more {
        cursor: pointer;
    }

    .type-box {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        .item {
            position: relative;
            width: 50%;
            .inner-info {
                .img {
                    width: 15px;
                }
            }
            .type-list {
                position: absolute;
                top: 48px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                box-shadow: 0px 2px 5px 0px rgba(151, 151, 151, 0.4);
            }
        }
        .item-lt {
            .inner-info {
                border-right: 1px solid #eee;
            }
            /*.type-lt {
                right: 30px;
            }*/
        }
        /*.item-rt {
            .type-rt {
                right: 15px;
            }
        }*/
    }

    .item-box {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        .item-title {
            font-size: 20px;
        }
        .desc {
            line-height: 21px;
        }
    }

    @media (max-width: $screen-md) {
        .page {
            background-color: white;
        }
        .search-box {
            width: 95%;
            height: 40px;
            background-color: #F8F8F8;
            box-shadow: none;
            span {
                font-size: 14px !important;
                color: #7E7E7E;
            }
            input {
                font-size: 14px;
            }
            .search {
                width: 90px;
                line-height: 40px;
                font-size: 14px;
            }
        }
        .left-box {
            display: none !important;
        }
        .item-box {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            .on-hover {
                box-shadow: none;
            }
            .item-title {
                font-size: 16px;
            }
            .desc {
                text-align: left;
            }
        }
    }

    @media (max-width: $screen-sm) {
        .item-hidden-sm {
            display: none;
        }
    }
</style>
